<script>
    import Button from './Button.svelte';
    import Modal from './Modal.svelte';

    import { paddle_purchase } from './utils/paddle';
    import { logEvent } from './utils/logs';
    import { goto } from '$app/navigation';
    import { discount_data } from './stores/globals';
    import { browser } from '$app/environment';
    import { format_price } from './utils/format_price';
    import { page } from '$app/stores';

    let session = $page.data.session;

    /** @type {Product} */
    export let product;

    export let cta = 'Buy Now';
    export let cta_free = 'Unlock the Full Course';
    export let hidden = false;
    export let feature = 'generic';
    export let loading = false;
    export let stopPropagation = false;
    export let no_price = false;

    /** @type {'default' | 'small'}*/
    export let style = 'default';

    let className = '';
    export { className as class };

    export const show = () => {
        handle_purchase();
    };

    let show_error = false;

    let discount_applicable = false;

    const price_value = product.price?.price || 0;
    const price_fx = product.price?.price_fx || 'USD';
    const price_str = format_price(price_value, price_fx);

    let price_effective_value = price_value;
    let price_effective_str = format_price(price_effective_value, price_fx);
    let is_free = !price_value;

    $: if ($discount_data && $discount_data.active) handle_discount_change();

    const handle_discount_change = () => {
        discount_applicable =
            $discount_data.active &&
            ($discount_data?.restrict_to?.length
                ? $discount_data.restrict_to.includes(product.price.product_id)
                : true);

        if (!discount_applicable) return;

        price_effective_value =
            price_value - (price_value * $discount_data?.amount || 0) / 100;

        price_effective_str = format_price(price_effective_value, price_fx);
        is_free = price_effective_value <= 0;
    };

    handle_discount_change();

    const success_cb = (event) => {
        document.removeEventListener('paddle_success', success_cb);
        document.removeEventListener('paddle_close', close_cb);

        const data = event.detail;

        logEvent('purchase:success', {
            course: product.slug,
            feature
        });

        window.Paddle?.Checkout?.close();
        show_error = false;

        let redirect_url = '';

        switch (product.type) {
            case 'course':
                redirect_url = `/courses/${product.slug}/lessons`;
                break;
            case 'worksheet':
                redirect_url = `/worksheets/${product.slug}`;
                break;
            case 'bundle':
                redirect_url = `/bundles/${product.slug}`;
                break;
        }

        loading = false;
        goto(`/purchase-success?redirectTo=${redirect_url}`);
    };

    const close_cb = (event) => {
        document.removeEventListener('paddle_success', success_cb);
        document.removeEventListener('paddle_close', close_cb);
        const data = event.detail;
        console.log('paddle close', product.slug);
        loading = false;
        logEvent('purchase:close', {
            course: product.slug,
            feature
        });
    };

    const handle_purchase = (e) => {
        if (stopPropagation) e.stopPropagation();

        loading = true;

        logEvent('purchase:show', { course: product.slug, feature });

        document.addEventListener('paddle_success', success_cb);
        document.addEventListener('paddle_close', close_cb);

        paddle_purchase({
            price_id: product.price.price_id,
            discount_id: $discount_data.active
                ? $discount_data?.discount_id || null
                : null,
            email: session?.user?.email || null
        });
    };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
{#if !hidden}
    <div
        on:click={handle_purchase}
        class="relative {style === 'small' ? 'inline-block' : ''} {className}"
    >
        <slot>
            {#if style == 'small'}
                <Button
                    primary
                    class="whitespace-nowrap"
                    loading={!browser || loading}
                    loading_spinner={browser}
                >
                    {is_free ? cta_free : cta}
                </Button>

                {#if !no_price}
                    {#if price_effective_value !== price_value && !is_free}
                        <s>{price_str}</s> {price_effective_str}
                    {:else if !is_free}
                        {price_str}
                    {/if}
                {/if}
            {:else}
                <Button
                    primary
                    xl
                    class="whitespace-nowrap"
                    block
                    loading={!browser || loading}
                    loading_spinner={browser}
                >
                    {is_free ? cta_free : cta}

                    <svelte:fragment slot="small">
                        {#if is_free}
                            Reedeem your gift
                        {:else if price_effective_value !== price_value && !is_free}
                            <s>{price_str}</s>
                            {price_effective_str}
                            {#if 'lessons_count' in product}
                                for {product.lessons_count} lessons
                            {/if}
                        {:else}
                            {price_str}
                            {#if 'lessons_count' in product}
                                for {product.lessons_count} lessons
                            {/if}
                        {/if}
                    </svelte:fragment>
                </Button>
            {/if}
        </slot>

        {#if style == 'default' && discount_applicable && price_value}
            <div
                class="text-center text-sm font-bold
                px-2 py-1
                bg-purple-600 text-purple-50 rounded-full
                shadow-md
                cursor-pointer

                {style === 'default'
                    ? 'absolute -top-4 -right-4 '
                    : 'inline-block ml-2'}
            "
            >
                {#if $discount_data.amount == 100}
                    Free
                {:else}
                    &minus;{$discount_data.amount}%
                {/if}
            </div>
        {/if}
    </div>
{/if}

{#if show_error}
    <Modal on:close={() => (show_error = false)}>
        <h1>Something went wrong</h1>
        <p>We could not process your purchase automatically</p>
        <p>
            Please contact us via <a href="mailto:support@critikid.com"
                >support@critikid.com</a
            > to restore your purchase
        </p>
    </Modal>
{/if}
