import { browser } from "$app/environment";

export const format_price = (value, fx_code = 'USD') => {
    
    let locale = 'en-CA'; // use US$ instead of $ for ssr 
    if (browser) locale = window?.navigator?.language ?? 'en-CA';

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: fx_code,
        currencyDisplay: 'symbol',
        minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    }).format(value);
}